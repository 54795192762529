<template>
  <w-form-field v-bind="$attrs" v-on="$listeners">
    <template #default="{ inputProps, inputListeners }">
      <div class="phone-form-field__container">
        <input
          ref="input"
          class="phone-form-field__input"
          type="tel"
          v-bind="getStrippedInputProps(inputProps)"
          v-on="{
            ...inputListeners,
            input: handleInput,
            blur: handleBlur,
          }"
        />
      </div>
    </template>
  </w-form-field>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

import i18n from '../../i18n';

import FormField from './FormField';

export default {
  computed: {
    ...mapState(['facility', 'staticUrl']),
  },
  methods: {
    ...mapActions('checkoutForm', ['setFormValue', 'setFormError']),
    getStrippedInputProps(inputProps) {
      const { value, strippedProps } = inputProps;
      this.value = value;
      return strippedProps;
    },
    handleBlur() {
      this.setFormError({
        name: this.$attrs.name,
        value: !this.iti.isValidNumber()
          ? i18n.gettext('Please enter a valid phone number.')
          : null,
      });
    },
    handleInput() {
      this.setFormValue({
        name: this.$attrs.name,
        value: this.iti.getNumber(),
      });
    },
  },
  mounted() {
    const { input } = this.$refs;
    input.value = this.value;
    input.addEventListener('countrychange', this.handleInput);
    this.iti = intlTelInput(this.$refs.input, {
      autoPlaceholder: 'off',
      initialCountry: this.facility.country,
      countrySearch: false,
      utilsScript: `${this.staticUrl}js/widgetItiUtils.js`,
      validationNumberType: 'FIXED_LINE_OR_MOBILE',
      formatAsYouType: false,
    });
  },
  beforeDestroy() {
    this.$refs.input.removeEventListener('countrychange', this.handleInput);
  },
  components: {
    'w-form-field': FormField,
  },
};
</script>

<style lang="scss">
.iti {
  width: 100%;
}
.phone-form-field__container {
  position: relative;
}
.phone-form-field__input {
  padding-left: 52px !important;
  width: 100%;
}
</style>
